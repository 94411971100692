<template>
  <div>离职数据</div>
</template>

<script>
export default {
  name: "ResginationData",
};
</script>

<style>
</style>